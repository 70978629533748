#book-page-container {
	-webkit-print-color-adjust: exact;
	font: 20px/30px 'PT Serif',Georgia,serif;
	background: #f9f9f9;
	text-rendering: optimizeLegibility;
	color: #1c1c1c;
}

.book-container {
	margin: 1em auto;
	max-width: 700px;
	width: 100%;
	padding: 10px;
}

#page-counter {
	background-color: #fff;
	position: fixed;
	top: 5em;
	right: 1em;
	font-size: 0.85em;
	opacity: 0.5;
}

.cover {
	min-height: 100vh;
	width: 100%;
	background: #262626;
	position: relative;
	color: #ff4742;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cover h1, h2, h3, h4 {
	color: #fff;
}

#book-chapters h1, h2, h3, h4 {
	color: #262626;
}

.cover a {
    color: white;
}

.comment {
	font-style: italic;
	padding-top: 10px;
	margin-bottom: 3rem;
	padding: 1rem;
}


.comment-gray-bg {
    border-top: 1px solid grey;
	background-color: #eee;
}

.comment-no-bg {
	border-top: 1px solid grey;
}

#menu-button-open {
	background-color: darkgray;
	position: fixed;
	top: 100px;
	left: 0px;
	padding: 5px;
}

#menu-button-close {
	background-color: darkgray;
	position: fixed;
	top: 100px;
	left: 300px;
	padding: 5px;
}

#menu-button-open:hover {
	cursor: pointer;
	background-color: #444242;
}

#menu-button-close:hover {
	cursor: pointer;
	background-color: #444242;
}

#page-up {
	background-color: darkgray;
	position: fixed;
	bottom: 0;
	right: 100px;
	padding: 5px;
}

#page-up:hover {
	cursor: pointer;
	background-color: #444242;
}

#book-menu {
	position: fixed;
	background-color: #fff;
	border: 1px solid darkgray;
	top: 0;
	left: 0;
	width: 300px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	overflow-y: scroll;
	padding-bottom: 3rem;
	padding-top: 3rem;
}

#book-menu a {
	margin-bottom: 2rem;
	color: #262626;
}

.chapter {
	margin-bottom: 3rem;
	border-top: 1px solid darkgray;
	padding-top: 1rem;
}

.twitter-tweet {
    border: 1px solid gray;
    padding: 1rem;
}

.img-container img {
	max-width: 100%;
}

.book-price-crossed {
	text-decoration: ;
}

.book-price-active {}

.buy-button {
	margin: 1rem;
	background-color: white;
	font-size: 2rem;
	padding: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-radius: 5px;
	background-color: #ff4742;
	color: white;
	font-weight: 700;
}

.buy-button:hover {
	cursor: pointer;
	background-color: #1c1c1c;
	border: 1px solid #ff4742;
	color: #ff4742;
}

.buy-button s {
	margin: 0.5rem;
}

.cover h1 {
	font-size: 3rem;
	font-weight: 700;
	padding-bottom: 2rem;
}

.book-quote {
	font-style: italic;
	background-color: #eee;
	padding: 2rem;
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.twitter-quote {
	background-color: #eee;
	padding: 2rem;
}

#table-of-contents {
	display: flex;
	flex-direction: column;
}

#table-of-contents a {
	padding-bottom: 0.5rem;
	color: #262626;
}

.stripe-modal {
    background-color: #fff;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 100px;
    padding: 25px;
    height: 50vh;
    position: fixed;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    outline: 0;
    border-radius: 15px;
}

.start-button {
	width: 250px;
	margin: 1rem;
	background-color: white;
	font-size: 1.3rem;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #ff4742;
	color: white;
	font-weight: 700;
}

.start-button:hover {
	cursor: pointer;
	background-color: #1c1c1c;
	border: 1px solid #ff4742;
	color: #ff4742;
}

.start-button s {
	margin: 0.5rem;
}

.buy-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.buy-email-input {
    width: 250px;
    margin: 1rem;
    padding: 0.5rem;
}

.buy-modal-close-button:hover {
    cursor: pointer;
}

.buy-modal-close-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.login-button {
    margin-left: 1rem;
    border-radius: 5px;
}

.login-button:hover {
    text-decoration: underline;
}