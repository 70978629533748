#login {
  height: 100vh;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/img_main%2Flogin_1500.jpg?alt=media&token=b6be0ed8-3a70-4606-aefa-0851d0f29a62");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px; }
  #login .form {
    background-color: white;
    border-radius: 15px;
    width: 300px;
    padding: 20px; }
    #login .form input {
      border-radius: 5px;
      padding: 5px; }
    #login .form button {
      width: 100%; }
  #login .hide {
    opacity: 0;
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear; }
  #login .show {
    opacity: 1;
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear; }

#register {
  height: 100vh;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/img_main%2Fauth.jpg?alt=media&token=ec226eb0-e2bf-4862-9441-60afdb64f937");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px; }
  #register .form {
    background-color: white;
    border-radius: 5px;
    width: 300px;
    padding: 20px; }
    #register .form input {
      border-radius: 5px;
      padding: 5px; }
    #register .form button {
      width: 100%; }
  #register .hide {
    opacity: 0;
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear; }
  #register .show {
    opacity: 1;
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear; }

#feedPhotoItem {
  border-top: 2px solid #eee;
  padding-top: 15px; }
  #feedPhotoItem img {
    max-width: 65%;
    border-radius: 25px;
    box-shadow: 10px 10px 5px #E0E0E0; }
  #feedPhotoItem img:hover {
    cursor: pointer;
    box-shadow: 0 0 2px 3px rgba(0, 140, 186, 0.5); }
  @media (min-width: 992px) {
    #feedPhotoItem img {
      max-width: 65%; } }

#homeImage {
  color: white;
  background-color: black;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url("https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/img_main%2Fvan_1500.jpg?alt=media&token=a85a8e5d-bc1d-4aba-9f2a-3582a94be8bf");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 65px; }
  #homeImage .home-container h1 {
    font-size: 3.2rem;
    font-weight: 600;
    background-color: #343a40;
    opacity: 80%;
    padding: 10px;
    border-radius: 10px; }
  #homeImage .home-container h3 {
    font-size: 2rem;
    font-weight: 600;
    background-color: #343a40;
    opacity: 80%;
    padding: 10px;
    border-radius: 10px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 10px; }
  #homeImage .home-container .button-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
    #homeImage .home-container .button-wrapper span {
      font-size: 2rem;
      font-weight: 500;
      margin-right: 20px; }
    #homeImage .home-container .button-wrapper .btn {
      font-weight: bold;
      font-size: 1.3rem;
      padding: 8px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 25px;
      margin: 10px; }
  @media (max-width: 576px) {
    #homeImage .home-container h1 {
      font-size: 1.5rem; } }

.mapWrapper {
  display: flex;
  flex-direction: row; }

#mapViewContainer {
  flex: 1;
  margin: 0;
  padding: 0;
  border: 10px solid black;
  height: 90vh; }

.photos-preview {
  display: flex;
  flex-direction: column;
  background-color: #E0E0E0;
  scroll-behavior: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  padding: 5px; }
  .photos-preview img {
    border: 5px solid #eee;
    width: 180px;
    margin-right: 25px;
    margin-bottom: 5px;
    border-radius: 25px;
    box-shadow: 2px 2px 5px #E0E0E0; }
  .photos-preview img:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 5px rgba(0, 140, 186, 0.5); }
  .photos-preview .change-page-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-right: 15px; }
    .photos-preview .change-page-wrapper i {
      color: #BDBDBD; }
    .photos-preview .change-page-wrapper i.active {
      color: #424242; }
      .photos-preview .change-page-wrapper i.active:hover {
        cursor: pointer; }

nav {
  margin: 0px;
  position: relative;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
  height: 80px;
  background-color: black; }
  nav .navbar-brand {
    color: white;
    font-weight: bold;
    font-size: 1.8rem; }
  nav ul {
    position: relative; }
    nav ul form {
      background-color: white;
      border-radius: 10px;
      position: relative;
      z-index: -1; }
      nav ul form input {
        border-radius: 10px;
        padding-left: 10px;
        border: none;
        height: 32px;
        width: 200px; }
      nav ul form input:focus {
        outline: none; }
      nav ul form i {
        background-color: white;
        padding: 7px;
        border: 0px;
        border-radius: 10px; }
      nav ul form button {
        background-color: white; }
      @media (max-width: 656px) {
        nav ul form {
          visibility: hidden; } }
  nav li {
    margin: 10px; }
  nav .btn {
    border-radius: 10px; }
  nav #upload-link {
    color: black;
    text-decoration: none; }
  nav .avatar-show-photo {
    width: 30px;
    border-radius: 25px;
    margin: 10px; }

@media (max-width: 576px) {
  .navbar-nav {
    background-color: #343A40;
    padding: 10px;
    position: relative;
    z-index: 20; } }

footer {
  bottom: 0;
  width: 100%;
  color: white;
  font-size: 1.35rem;
  margin: 0;
  opacity: 0.75;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  footer .social-wrapper {
    margin: 0;
    margin-right: 30px; }
  footer i {
    margin: 8px;
    margin-right: 15px;
    opacity: 65%; }
    footer i:hover {
      opacity: 150%;
      cursor: pointer; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

#update-profile-container input,
#update-profile-container textarea {
  width: 100%;
  padding: 10px; }

.profile-top {
  color: white;
  border-radius: 10%; }

.profile-social-icon {
  color: white; }

.profile-social-icon :hover {
  color: #707070; }

.round-img {
  border-radius: 50%; }

.profile-photo-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .profile-photo-items-container img {
    height: 150px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid grey; }

#upload-container {
  width: 100%;
  position: absolute;
  top: 20;
  margin-top: -1px; }
  #upload-container .btn {
    font-size: 1.5rem; }

#uploadDetails img {
  max-width: 200px; }

#updatePhoto img {
  max-width: 200px; }

#mapContainer {
  margin: 0;
  padding: 0;
  border: 1px solid black;
  height: 70vh; }

Map {
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0; }

.about-wrapper {
  margin: 20px;
  margin-bottom: 35px;
  margin-left: 50px;
  margin-right: 50px;
  color: #3B454E;
  font-family: gitbook-content-font,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif; }
  .about-wrapper h1 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E0E0E0; }
  .about-wrapper .team-wrapper {
    display: flex;
    flex-direction: row; }
    .about-wrapper .team-wrapper .team-photo img {
      max-width: 150px;
      margin: 0;
      margin-right: 25px; }
      .about-wrapper .team-wrapper .team-photo img:hover {
        cursor: auto; }
    .about-wrapper .team-wrapper i {
      margin-right: 5px; }

.d-block {
  display: block !important; }

.photo-modal {
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  outline: 0; }

#currentPhoto {
  margin: 0;
  padding: 0; }
  #currentPhoto img {
    max-width: 70%;
    padding-top: 0px;
    margin-top: -15px; }
  #currentPhoto i {
    color: black;
    padding: 0;
    margin: 0; }
  #currentPhoto i:hover {
    color: #8a8c81;
    cursor: pointer; }
  #currentPhoto .iconsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px; }
    #currentPhoto .iconsWrapper .editIconWrapper .editIcon {
      padding: 10px; }
    #currentPhoto .iconsWrapper .likeIconWrapper {
      width: 50px; }
      #currentPhoto .iconsWrapper .likeIconWrapper .likeIcon {
        padding: 5px;
        border-radius: 25px; }
      #currentPhoto .iconsWrapper .likeIconWrapper .likeIcon:hover {
        background-color: #eee; }
  #currentPhoto .photoModalLink {
    color: #656565; }
  #currentPhoto #currentPhotoImg {
    padding: 10px;
    padding-bottom: 30px; }
  #currentPhoto #deletePhoto:hover {
    text-decoration: underline;
    cursor: pointer; }
  #currentPhoto .avatar-show-photo {
    width: 40px;
    border-radius: 25px;
    margin: 10px; }

.mapLink {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/img_main%2FmapLink.png?alt=media&token=cdc6e859-63ca-4f42-bb0e-2a9e5f5c14f5");
  width: 300px;
  height: 150px;
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  font-size: 20px;
  color: black;
  font-weight: 800; }

@media (min-width: 992px) {
  #currentPhoto img {
    max-width: 70%; } }

.review-filter-modal {
  background-color: #eee;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 100px;
  padding: 25px;
  height: 50vh;
  position: fixed;
  top: 0;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  outline: 0;
  border-radius: 15px; }
