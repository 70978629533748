.thumbnail-link:hover {
  text-decoration: none; }

.thumbnail-link .thumbnail-container {
  background-size: cover;
  width: 250px;
  height: 300px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  color: white; }
  .thumbnail-link .thumbnail-container:hover {
    border: 1px solid black; }
  .thumbnail-link .thumbnail-container h1 {
    font-weight: 900; }
