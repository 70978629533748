.country-top {
  background-size: cover;
  height: 200px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: white; }
  .country-top h1 {
    font-size: 4rem; }

table, td {
  border: 1px solid #333;
  padding: 10px;
  vertical-align: top; }

table {
  width: 100%; }

thead, tfoot {
  background-color: #333;
  color: #fff; }

.country-economy img {
  max-width: 80%; }
  .country-economy img:hover {
    text-decoration: none; }
