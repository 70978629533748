.survey-section {
  width: 90%; }
  .survey-section .survey-input-string {
    width: 80%;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 5px; }
  .survey-section .survey-input-select {
    width: 80%;
    border-radius: 5px; }
  .survey-section .survey-input-boolean {
    border-radius: 5px;
    border: 1px solid gray; }
  .survey-section .form-group {
    padding-bottom: 10px;
    border-bottom: 1px dashed gray;
    text-align: left; }
  .survey-section .submit-btn {
    font-weight: 700;
    font-size: 1.2rem;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px; }
